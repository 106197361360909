import { Component, Logger, store } from 'lib'
import Image from 'Component/Asset/Image/Image'
import InformLoading from 'Component/Inform/Loading/InformLoading'
import InformBubble from 'Component/Inform/Bubble/InformBubble'
import RewardsDropsCard from 'Component/Rewards/DropsCard/RewardsDropsCard'
import rewardsDrops from 'Common/Service/Rewards/Drops/RewardsDrops'
import relapi from 'Common/Service/RelApi/RelApi'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'
import Accordion from '../../../Component/Accordion/Accordion'
import { sortLeaguesByPriority } from '../../../Common/Service/RelApi/RelApi'
import analytics from 'Common/Service/Analytics/Analytics'

class RewardsDrops extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
  }

  componentWillMount () {
    this.addListeners()
  }

  addListeners () {
    this.onClickSave = () => {
      this.setState({ sidebarEnabled: false })
    }

    this.onClickSidebarKnuckle = () => {
      this.setState({ sidebarEnabled: true })
    }
  }

  fetchList () {
    return Promise.all([rewardsDrops.fetchList(), relapi.fetchLeagues(true)])
      .then(([drops, leagues]) => {
        const leaguesWithDrops = this.filterLeaguesWithDrops(
          leagues.leagues,
          drops
        )
        const dropsByOwnLeague = this.mapDropsByLeagueAndYearEarned(drops)

        const sortByLeague = !!Object.values(leaguesWithDrops).length

        const sortedLeagues = Object.values(leaguesWithDrops).sort(
          sortLeaguesByPriority
        )
        const selectedLeague
          = this.state.selectedLeague
          || sortedLeagues && sortedLeagues.length && sortedLeagues[0]

        this.setState({
          leagues: leaguesWithDrops,
          drops: dropsByOwnLeague,
          sortByLeague,
          selectedLeague
        })
      })
      .catch((error) => {
        this.setState({ error })
      })
  }

  componentWillUnmount () {
    store.removeListener('riotBar.authChecked', this.sessionReadyHandler)
  }

  componentDidMount () {
    // Make sure drops services are called after websession cookies are set
    store.onChange(
      'riotBar.authChecked',
      this.sessionReadyHandler = () => this.fetchList()
    )
  }

  filterLeaguesWithDrops (leagues, drops) {
    return leagues
      .filter((league) => drops.some((drop) => drop.leagueID === league.id))
      .reduce((map, league) => {
        map[league.id] = league
        return map
      }, {})
  }

  isSelected (league) {
    return (
      this.state.selectedLeague && this.state.selectedLeague.id === league.id
    )
  }

  mapDropsByLeagueAndYearEarned (drops) {
    return drops.reduce((map, drop) => {
      const dateEarned = new Date()

      dateEarned.setTime(drop.unlockedDateMillis)
      const year = dateEarned.getFullYear()

      if (!map[drop.leagueID]) {
        map[drop.leagueID] = {}
      }

      const years = map[drop.leagueID]

      if (!years[year]) {
        years[year] = [drop]
      }
      else {
        years[year].push(drop)
      }

      return map
    }, {})
  }

  selectLeague (league) {
    this.setState({ selectedLeague: league })
    analytics.trackEvent('drop_filter_selected', { league: league.slug })
  }

  renderCards (drops, selectedLeague) {
    if (this.state.sortByLeague && selectedLeague) {
      return (
        <div class="years">
          { this.renderCardsForLeague(drops[selectedLeague.id]) }
        </div>
      )
    }

    const allDrops = Object.values(drops)
      .flatMap(Object.values)
      .flat()

    return (
      <div class="drops">{ this.renderCardsSortedByDateUnlocked(allDrops) }</div>
    )
  }

  renderCardsSortedByDateUnlocked (drops) {
    // sort by unlocked date (recent drops first)
    return util
      .sortListByPropertyValue(drops, 'unlockedDateMillis')
      .reverse()
      .map((drop) => (
        <RewardsDropsCard
          id={ drop.dropID }
          productImage={ drop.dropsetImages.cardUrl }
          sponsorImage={ drop.sponsorImages.cardOverlayUrl }
          backgroundColor={ drop.rarity && drop.rarity.badgeBackgroundColor }
          foregroundColor={ drop.rarity && drop.rarity.badgeForegroundColor }
          unlockedDate={ drop.unlockedDateMillis }
          title={ drop.dropsetTitle }
          isRare={ drop.rarity && drop.rarity.rare }
          styleDeclaration={ drop.rarity && drop.rarity.styleDeclaration }
        />
      ))
  }

  renderCardsForLeague (dropsByYear) {
    const years = Object.keys(dropsByYear)
      .sort()
      .reverse()

    return years.map((year, index) => (
      <Accordion
        titleClass="year-title"
        title={ year }
        open={ index === 0 }
        chevron="after-title">
        <div class="drops">
          { this.renderCardsSortedByDateUnlocked(dropsByYear[year]) }
        </div>
      </Accordion>
    ))
  }

  renderNoDrops () {
    const noDrop = (className) => (
      <div class={ 'no-drops ' + className }>
        <div class="image">
          <Image
            class="img"
            src="/rewards/drops/logo-stroke.png"
            size="100x83"
          />
        </div>
        <div class="label">{ locale.translate('rewards.drops.noDrops') }</div>
        <div class="title">{ locale.translate('rewards.drops.getDrops') }</div>
      </div>
    )

    const classNames = util.classNames(this.constructor.name, 'no-drops')

    return (
      <div class={ classNames }>
        { noDrop('right') }
        { noDrop('left') }
        { noDrop('center') }
      </div>
    )
  }

  renderErrorOrLoading (error) {
    return (
      <div class={ this.constructor.name }>
        { error ? (
          <InformBubble theme="error" icon="error">
            { error.message }
          </InformBubble>
        )
          : <InformLoading/>
        }
      </div>
    )
  }

  renderLeagues (leagues, dropCountsByLeague) {
    return leagues.map((league, index) => {
      const filterOptionClasses = util.classNames(
        'league',
        this.isSelected(league) && 'selected'
      )

      return (
        <li
          class={ filterOptionClasses }
          onClick={ () => this.selectLeague(league, index) }
          role="button">
          <div class="info">
            <Image class="image" src={ league.image } size="60"/>
            <div class="label">
              <div class="name">{ league.name }</div>
              <div class="dropCount">
                { locale.translate('rewards.drops.sidebar.dropCount', {
                  number: dropCountsByLeague[league.id]
                }) }
              </div>
            </div>
          </div>
        </li>
      )
    })
  }

  renderSidebar (leagues, drops) {
    const sortedLeagues = Object.values(leagues)

    sortedLeagues.sort((leagueA, leagueB) =>
      sortLeaguesByPriority(leagueA, leagueB)
    )

    const dropCountsByLeague = Object.keys(drops).reduce((map, leagueID) => {
      const years = drops[leagueID]
      const dropCount = Object.keys(years).reduce(
        (total, year) => total + years[year].length,
        0
      )

      map[leagueID] = dropCount
      return map
    }, {})

    const classNames = util.classNames(
      'sidebar',
      this.state.sidebarEnabled ? 'sidebar-enabled' : ''
    )

    return (
      <div class={ classNames }>
        <div class="header">
          <div class="filter">
            { locale.translate('rewards.drops.sidebar.title') }
          </div>
          <div class="save" onClick={ this.onClickSave } role="button">
            { locale.translate('navigation.save') }
          </div>
        </div>
        <ul class="leagues">
          { this.renderLeagues(sortedLeagues, dropCountsByLeague) }
        </ul>
      </div>
    )
  }

  renderSidebarKnuckle () {
    return (
      <div
        class="sidebar-knuckle"
        onClick={ this.onClickSidebarKnuckle }
        role="button">
        { locale.translate('rewards.drops.sidebar.title') }
      </div>
    )
  }

  render () {
    if (!this.state.drops || this.state.error) {
      return this.renderErrorOrLoading(this.state.error)
    }

    if (Object.values(this.state.drops).length === 0) {
      return this.renderNoDrops()
    }

    const classNames = util.classNames(
      this.constructor.name,
      !this.state.sortByLeague && 'no-sidebar'
    )

    return (
      <div class="year-wrapper">
        { this.state.sortByLeague && this.renderSidebarKnuckle() }
        <div class={ classNames }>
          { this.state.sortByLeague
            && this.renderSidebar(this.state.leagues, this.state.drops) }
          { this.renderCards(this.state.drops, this.state.selectedLeague) }
        </div>
      </div>
    )
  }
}

export default RewardsDrops
